<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Функционал: Рассылки</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Архив рассылок</h5>
        </div>
        <div class="d-flex flex-column justify-content-center ml-3">
          <a class="btn btn-primary" @click="$router.push({ path: '/functional/mailings/create' })">Создать</a>
        </div>
      </div>
      <div class="card-body">
        <mailings-table :list="list" :paginate="pagination" @handleTableChange="handleTableChange" @getList="getList" />
      </div>
      <edit-mailings-modal ref="editModal" @getList="$emit('getList')"/>
    </div>
  </div>
</template>
<script>
import MailingsTable from '@/components/functional/mailings/MailingsTable'
import EditMailingsModal from '@/components/functional/mailings/EditMailingsModal'
export default {
  name: 'index',

  components: {
    EditMailingsModal,
    MailingsTable,
  },

  data: () => ({
    list: [],
    pagination: {
      current: 1,
      total: 1,
      pageSize: 10,
    },
  }),

  created() {
    this.getList()
  },

  computed: {
    queryParams() {
      return {
        page: this.pagination.current,
      }
    },
  },

  methods: {
    async getList() {
      const params = this.queryParams

      const { pagination, data: notices } = (await this.$services.get('admin/notice', { params })).data.data.notices

      this.list = notices
      this.pagination = {
        current: pagination.current_page,
        total: pagination.total,
        pageSize: pagination.per_page,
      }
    },

    handleTableChange(pagination, _, sorter) {
      this.pagination.current = pagination.current
      this.getList()
    },
  },
}
</script>
