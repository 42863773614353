<template>
  <div>
    <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="handleTableChange" :row-key="(record, index) => record.id + index">
      <span
        slot="id"
        slot-scope="text"
      >
        {{text}}
      </span>
      <span slot="action" slot-scope="record">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$router.push( { path: `/functional/mailings/${record.id}` } )">
          <i class="fe fe-eye mr-2" />
          Просмотр
        </a>
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$router.push( { path: `/functional/mailings/edit/${record.id}` } )">
          <i class="fe fe-eye mr-2" />
          Редактировать
        </a>
        <a-popconfirm
          title="Вы уверенны что хотите удалить?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="remove(record.id)"
        >
          <a href="javascript: void(0);" class="btn btn-sm btn-light">
            <small>
              <i class="fe fe-trash mr-2 pb-2" />
            </small>
            Удалить
          </a>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
export default {
  name: 'MailingsTable',

  props: {
    list: {
      type: Array,
      default: () => [],
    },
    paginate: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
        scopedSlots: { customRender: 'id' },
      },
      {
        title: 'Название',
        dataIndex: 'title',
      },
      {
        title: 'Cтатус',
        dataIndex: 'status',
      },
      {
        title: 'Получатель',
        dataIndex: 'recipient.full_name',
      },
      {
        title: 'Дата',
        dataIndex: 'format_date',
      },
      {
        title: 'Действия',
        scopedSlots: { customRender: 'action' },
      },
    ],
  }),

  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.$emit('handleTableChange', pagination, filters, sorter)
    },

    async remove(id) {
      await this.$services.delete(`/admin/notice/${id}`)
      await this.$emit('getList')
    },
  },
}
</script>
